// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

function TestimonialsOne() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid container item xs={12} lg={6} justifyContent="center" mx="auto" textAlign="center">
          <MKTypography variant="h2" mb={2}>
            Reviews:
          </MKTypography>
        </Grid>
        <Grid container spacing={3} mt={8}>
          <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultReviewCard
              color="info"
              name="Claudia Gonzalez"
              date="27 May"
              review="I absolutely am happy that I chose Doctor Rybalova as our family dentist. We just switched our insurance which meant changing our dentist and although I was happy with our old dentist, I am so glad that I found her! Our previous dentist was too far of a drive and the metered parking was expensive and hard to find. That’s not the case with Doctor Rybalova’s office. There’s an abundance of free parking spaces in the back. Saves me time having to look for parking, unlike our old dentist’s office.

              All appointments are handled by Pilar who is a gem of a person! She is so sweet and hospitable when patients check in. It’s almost as if you’re visiting a friend. I called in to request to be seen for that same day because of a severe toothache. It was effortless! Even without an appointment, the wait time isn’t that long. Pilar never makes you feel like you’re an inconvenience. They are very accommodating.
              
              My only issue is with Doctor Rybalova’s assistants, which isn’t really much of an issue. They don’t smile when they greet you when it’s your turn, which would be a bonus to the entire experience. Thank goodness for Pilar and her warm hospitality! She makes up for the lack of warmth from them.
              
              I’m very happy to leave this review. I’ve had many unfortunate experiences that has moved me to leave a lot of bad ones on Yelp and I am glad I am finally able to leave another honest and sincere positive review. If your plan accepts Doctor Rybalova, choose her. I am confident that you will love her too."
              rating={4.5}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultReviewCard
              color="info"
              name="Zarina Ronay"
              date="4 days ago"
              review="The staff and dentist are very welcoming and very caring my son is autistic and they have been more then caring for him during his appointments with them"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultReviewCard
              color="info"
              name="Konstantine Faleyev"
              date="2 months ago"
              review="Great dentist, has light touch and keeps you informed through every stage of the process.  Explained X-Ray and the visual findings in great detail as well as as the options for treatment.  The only dentist I found that cares."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
  );
}

export default TestimonialsOne;
