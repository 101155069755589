/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
//import DefaultNavbar from "examples/Navbars/DefaultNavbar";
//import DefaultFooter from "examples/Footers/DefaultFooter";
//import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
//import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
//import Pages from "pages/Presentation/sections/Pages";
import FormSimple from "pages/Presentation/sections/Form";
import MoreInfo from "pages/Presentation/sections/moreInfo";
import TestimonialsOne from "../../components/Reviews";
import { Helmet } from "react-helmet-async";

// Presentation page components
//import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
//import routes from "routes";
//import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import { Typography } from "@mui/material";

function Presentation() {
  return (
    <>
      <Helmet>
        <title>Irina Rybalova DDS</title>
        <meta
          name="description"
          content="Irina Rybalova DDS offers the best dental services in all of Freeport"
        />
        <link rel="canonical" href="/" />
      </Helmet>
      {window.innerWidth <= 500 && (
        <MKBox
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="#bdd6ff"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              />
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              ></MKTypography>
            </Grid>
          </Container>
        </MKBox>
      )}
      {window.innerWidth > 500 && (
        <MKBox
          minHeight="75vh"
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="#bdd6ff"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              />
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              ></MKTypography>
              <br />
            </Grid>
          </Container>
        </MKBox>
      )}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <br />
        <Typography variant="h1" style={{ textAlign: "center" }}>
          Irina Rybalova Dentistry
        </Typography>
        <br />
        <hr style={{ width: "75%", margin: "auto", height: "3px", backgroundColor: "#144875" }} />
        <Counters />
        <Information />
        <MoreInfo />
        <TestimonialsOne />
        <hr style={{ width: "75%", margin: "auto", height: "3px", backgroundColor: "#144875" }} />
        <br />
        <br />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1184.0145711170055!2d-73.5816428125113!3d40.65355482417072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c27bf4eca7c459%3A0x4a1f3fd7af40dae6!2sRybalova%20Irina%20DDS!5e0!3m2!1sen!2sus!4v1689521340786!5m2!1sen!2suskey=AIzaSyB-SVmnXr6d0EuJyanZovXnR7nqz0_zObM"
          width="100%"
          height="500"
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
        <FormSimple />
      </Card>
    </>
  );
}

export default Presentation;
